import * as React from 'react';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import { Trans, useTranslation } from 'react-i18next';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { ELandingRoutes } from 'const';
import SEO from 'components/Seo';
import Img from 'gatsby-image';
import { getTranslates } from 'helpers/translate.helper';

export default () => {
  const data = useStaticQuery(query);
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <Container>
      <SEO title={getTranslates(data.allStrapiLesLandCommon, 'header__how', language)} />

      <Row type="flex" align="top">
        <Col xs={24} lg={24} xl={12}>
          <Typography.Title className="clp-typography-title clp-typography-title_size_md mb-25 mt-30">
            {getTranslates(data.allStrapiLesLandHow, 'header', language)}
          </Typography.Title>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-20">
            {getTranslates(data.allStrapiLesLandHow, 'content1', language)}
          </Typography.Text>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-20">
            {getTranslates(data.allStrapiLesLandHow, 'content2', language)}
          </Typography.Text>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-20">
            {getTranslates(data.allStrapiLesLandHow, 'content3', language)}
          </Typography.Text>

          <Trans i18nKey="howPageLinks">
            <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-20">
              {getTranslates(data.allStrapiLesLandHow, 'link__1', language)}{' '}
              <Link to={ELandingRoutes.SignInfoCorporate} className="landing-link">
                {getTranslates(data.allStrapiLesLandHow, 'link__2', language)}
              </Link>
              {getTranslates(data.allStrapiLesLandHow, 'link__3', language)}{' '}
              <Link to={ELandingRoutes.SignInfo} className="landing-link">
                {getTranslates(data.allStrapiLesLandHow, 'link__4', language)}
              </Link>
            </Typography.Text>
          </Trans>
        </Col>

        <Col xs={0} lg={{ span: 20, offset: 2 }} xl={{ span: 12, offset: 0 }}>
          <div className="land-img__how">
            <Img fluid={data.art.childImageSharp.fluid} alt="Art" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const query = graphql`
  query {
    art: file(relativePath: { eq: "images/art.jpg" }) {
      childImageSharp {
        fluid(quality: 90, sizes: "(min-width: 1119px) calc(50vw - 40px), 560px", maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allStrapiLesLandHow {
      edges {
        node {
          header__en
          header__fr
          content1__en
          content1__fr
          content2__en
          content2__fr
          content3__en
          content3__fr
          link__1__en
          link__1__fr
          link__2__en
          link__2__fr
          link__3__en
          link__3__fr
          link__4__en
          link__4__fr
        }
      }
    }
    allStrapiLesLandCommon {
      edges {
        node {
          header__how__en
          header__how__fr
        }
      }
    }
  }
`;
